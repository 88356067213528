import {throttle} from 'throttle-debounce';

export function init() {

	let lastKnownScrollPosition = 0;
	let ticking = false;

	const headerElement = document.getElementById('header');
	const headerHeight = headerElement.offsetHeight;

	function hideHeader() {
		headerElement.classList.add('page__header--hidden');
	}

	function showHeader() {
		headerElement.classList.remove('page__header--hidden');
	}

	window.addEventListener(
		'scroll',
		throttle(300, () => {
			const delta = window.pageYOffset - lastKnownScrollPosition;
			lastKnownScrollPosition = window.scrollY
				? window.scrollY
				: document.documentElement.scrollTop;
			if (!ticking) {
				window.requestAnimationFrame(() => {
					if (delta > 0 && lastKnownScrollPosition > 0) {
						hideHeader();
					} else {
						showHeader();
					}
					ticking = false;
				});
				ticking = true;
			}
		})
	);

	window.addEventListener(
		'mousemove',
		throttle(300, e => {
			if (!ticking) {
				window.requestAnimationFrame(() => {
					if (e.clientY < headerHeight) {
						showHeader();
					}
					ticking = false;
				});
				ticking = true;
			}
		})
	);
}
